




















































import useApi from "@/use/api";
import useAttachment from "@/use/attachment";
import useUser from "@/use/user";
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch,
} from "@vue/composition-api";

export default defineComponent({
  components: {
    OFleetPolicy: () => import('@/components/organisms/o-fleet-policy.vue'),
    MCompanyFilter: () => import('@/components/molecules/m-company-filter.vue')
  },
  setup(_, { root }) {
    const { hasAccessTo, hasManyCompanies } = useUser({ root });
    const { axiosInstance } = useApi({ root });
    const { downloadAttachment } = useAttachment({ root })

    const model = reactive({ company: null as any })

    const loading = ref(false);

    const items = ref([]);
    const serverItemsLength = ref(0);

    const headers = computed(() => [
      { value: 'file', text: 'Plik', sortable: false },
      { value: 'date', text: 'Data', sortable: false },
      { value: 'actions', text: '', sortable: false, width: 1, align: 'end' }
    ]);

    const options = ref({ page: 1, itemsPerPage: 10 });
    const groupBy = computed(() => ((hasAccessTo.value('employee') || hasManyCompanies.value()) && !model.company) ? 'company.id' : null);

    const fetch = () => {
      loading.value = true;

      axiosInstance
        .get('fleet-policy', {
          params: {
            page: options.value.page || undefined,
            itemsPerPage: options.value.itemsPerPage || undefined,
            company: model.company ? model.company.id : undefined
          }
        })
        .then(({ data: { fleetPolicies, total } }) => {
          items.value = fleetPolicies
          serverItemsLength.value = total
        })
        .catch(() => {
          items.value = []
          serverItemsLength.value = 0
        })
        .finally(() => loading.value = false)
    };

    onMounted(fetch);
    watch([options], fetch, { deep: true });

    const deleteFleetPolicy = (id: string) => {
      axiosInstance
        .delete(`fleet-policy/${id}`)
        .then(fetch)
        .catch(console.log)
    }

    const preview = reactive({
      isOpen: false,
      id: null as null | string
    })

    const previewFleetPolicy = (id: string) => {
      preview.id = id
      preview.isOpen = true
    }

    const action = {
      download: (item: { file?: { id: string } | null }) => item.file?.id ? downloadAttachment(item.file.id) : void 0,
      delete: (item: { id: string }) => item.id ? deleteFleetPolicy(item.id) : void 0,
      preview: (item: { id: string }) => item.id ? previewFleetPolicy(item.id) : void 0
    }

    return {
      hasAccessTo,
      hasManyCompanies,

      model,

      loading,

      items,
      serverItemsLength,

      headers,
      options,
      groupBy,

      preview,
      action,
    };
  },
});
