var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"outlined":""}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$router.back()}}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1),_c('v-toolbar-title',[_vm._v("Polityka flotowa")]),_c('v-spacer'),(_vm.hasAccessTo('employee'))?_c('v-btn',{attrs:{"depressed":"","dark":"","color":"green","to":{ name: 'panel.fleet-policy.add' },"exact":""}},[_c('span',{staticClass:"d-none d-md-inline"},[_vm._v("Dodaj politykę")]),_c('span',{staticClass:"d-md-none"},[_vm._v("Dodaj")]),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-plus")])],1):_vm._e()],1),_c('o-fleet-policy',{key:_vm.preview.id,attrs:{"is-open":_vm.preview.isOpen,"id":[_vm.preview.id]},on:{"update:isOpen":function($event){return _vm.$set(_vm.preview, "isOpen", $event)},"update:is-open":function($event){return _vm.$set(_vm.preview, "isOpen", $event)}}}),(_vm.hasAccessTo('employee') || _vm.hasManyCompanies())?[_c('v-divider'),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"4","xl":"3"}},[_c('m-company-filter',{attrs:{"hide-details":"","outlined":"","dense":""},model:{value:(_vm.model.company),callback:function ($$v) {_vm.$set(_vm.model, "company", $$v)},expression:"model.company"}})],1)],1)],1),_c('v-divider')]:_vm._e(),_c('v-data-table',{attrs:{"loading":_vm.loading,"items":_vm.items,"headers":_vm.headers,"options":_vm.options,"group-by":_vm.groupBy},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var items = ref.items;
var headers = ref.headers;
return [((_vm.hasAccessTo('employee') || _vm.hasManyCompanies()) && items[0])?[_c('td',{attrs:{"colspan":headers.length}},[(items[0].company)?_c('v-icon',{staticClass:"mr-6"},[_vm._v("mdi-domain")]):_vm._e(),(items[0].company)?_c('strong',[_vm._v(_vm._s(items[0].company.name))]):_vm._e()],1)]:_vm._e()]}},{key:"item.file",fn:function(ref){
var item = ref.item;
return [(item.file)?_c('span',[_vm._v(_vm._s(item.file.name))]):_c('v-icon',[_vm._v("mdi-minus")])]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [(item.date)?_c('span',[_vm._v(_vm._s(_vm._f("moment")(item.date,'DD.MM.YYYY')))]):_c('v-icon',[_vm._v("mdi-minus")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{staticClass:"ml-2",attrs:{"icon":""},on:{"click":function($event){return _vm.action.preview(item)}}},[_c('v-icon',[_vm._v("mdi-eye")])],1),_c('v-btn',{staticClass:"ml-2",attrs:{"icon":"","disabled":!item.file},on:{"click":function($event){return _vm.action.download(item)}}},[_c('v-icon',[_vm._v("mdi-download")])],1),(_vm.hasAccessTo('employee'))?_c('v-btn',{staticClass:"ml-2",attrs:{"icon":""},on:{"click":function($event){return _vm.action.delete(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e()],1)]}}])})],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }